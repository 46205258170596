import styled from "styled-components";
import { Font10, Font14, Font16 } from "../../common-componnets/typography";
import { useNavigate } from "react-router-dom";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  transformedDate,
} from "../../util/date";
import { colors } from "../../common-componnets/color";
import { truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";

const PostList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0px 20px;
  margin-bottom: 32px;
`;

const NoResultWrapper = styled.div`
  padding: 63px 0px 63px 0px;
  text-align: center;
`;

const PostItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 94px;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled(Font14)`
  margin-bottom: 12px;
`;

const UserWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const UserIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 90px;
`;

const Border = styled.div`
  ${(p) => p.isLast && `display:none;`}
  height: 0.5px;
  background: #ddd;
  margin: 16px 0px 16px 0px;
`;

export const CategoryPgae = ({ postsData, pageName }) => {
  const navigate = useNavigate();
  console.log("hellow", postsData);
  return (
    <>
      {!postsData ? (
        <></>
      ) : postsData?.length === 0 ? (
        <NoResultWrapper>
          <Font16 bold color={colors.gray3}>
            {pageName}の投稿はありません
          </Font16>
        </NoResultWrapper>
      ) : (
        <PostList>
          {postsData?.map((v, i) => {
            return (
              <div key={i}>
                <PostItem onClick={() => navigate(`post/${v.id}`)}>
                  <ImgWrapper src={v.img ?? ""} />
                  <ContentWrapper>
                    <TagWrapper>
                      {v.category && (
                        <Tag>
                          <Font10>
                            {v.category.length === 2
                              ? v.category.join(" ＞ ")
                              : v.category}
                          </Font10>
                        </Tag>
                      )}
                    </TagWrapper>
                    <Font14 color={colors.black} bold>
                      {truncateString(v.title)}
                    </Font14>
                    <PostPrice color={colors.black} bold>
                      ¥{insertCommas(v.price)}
                    </PostPrice>
                    {/* vのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                    <UserWrapper>
                      <UserIcon src={v.userInfo.userPhotoUrl} />
                      <Font10>{v.userInfo.userName}</Font10>
                      <Font10 color={colors.gray1}>|</Font10>
                      <Font10>{calculateElapsedTime(v.postDate)}</Font10>
                    </UserWrapper>
                  </ContentWrapper>
                </PostItem>
                <Border isLast={postsData.length - 1 === i} />
              </div>
            );
          })}
        </PostList>
      )}
    </>
  );
};
