import { useState } from "react";
import { Font14 } from "../../common-componnets/typography";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { colors } from "../../common-componnets/color";
import { Modal } from "../../common-componnets/modal";
import { SideMenu } from "../home/sideMenu";
import { Tab } from "../../common-componnets/tab";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import RequestedOwnPosts from "./requestedOwnPosts";
import ContactedPosts from "./contactedPosts";

const RequestWrapper = styled.div`
  position: fixed;
  top: 0px;
  background: white;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 20px;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const Request = () => {
  const [showModalName, setShowModalName] = useState("");
  const [showingPageName, setShowinfPageName] = useState("requestedOwnPosts");
  const navigate = useNavigate();

  return (
    <>
      <RequestWrapper>
        <Header>
          <ArrowLeft color={colors.black} onClick={() => navigate(-1)} />
          <Font14 color={colors.black} bold>
            問い合わせ
          </Font14>
          <Menu
            color={colors.black}
            onClick={() => setShowModalName("sideMenu")}
          />
        </Header>
        <Tab
          leftText={"問い合わせのある投稿"}
          rightText={"問い合わせした投稿"}
          onClickLeft={() => setShowinfPageName("requestedOwnPosts")}
          onClickRight={() => setShowinfPageName("contactedPosts")}
          isFocusedLeft={showingPageName === "requestedOwnPosts"}
          isFocusedRight={showingPageName === "contactedPosts"}
        />
      </RequestWrapper>
      {showingPageName === "requestedOwnPosts" && (
        <RequestedOwnPosts setShowinfPageName={setShowinfPageName} />
      )}
      {showingPageName === "contactedPosts" && <ContactedPosts />}
      {showModalName === "sideMenu" && (
        <Modal onClick={() => setShowModalName("")}>
          <SideMenu
            onClick={(e) => e.stopPropagation()}
            closeSideMenu={() => setShowModalName("")}
          />
        </Modal>
      )}
    </>
  );
};

export default Request;
