import styled from "styled-components";
import { Footer } from "./footer";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import {
  Font10,
  Font12,
  Font14,
  Font18,
  Font20,
} from "../../common-componnets/typography";
import { useEffect, useState } from "react";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import useFetchUser from "../../hooks/useFetchUser";
import { calculateElapsedTime, postedTime } from "../../util/date";
import { useFetchCategory } from "../../hooks/useFetchCategory";
import { colors } from "../../common-componnets/color";
import { CategoryPgae } from "./categoryPage";
import { ReactComponent as Search } from "../../assets/search.svg";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { ReactComponent as ArrowBottom } from "../../assets/arrowBottom.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as SearchBig } from "../../assets/search-big.svg";
import { ReactComponent as ArrowTop } from "../../assets/arrowTop.svg";
import { ReactComponent as CheckMark } from "../../assets/checkMark.svg";
import { ReactComponent as Corpo } from "../../assets/corpo.svg";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import QR3 from "../../assets/qr3.png";
import Sankaku from "../../assets/sankaku.png";
import { ReactComponent as QrLogo } from "../../assets/qrLogo.svg";
import { Modal } from "../../common-componnets/modal";
import { SideMenu } from "./sideMenu";
import { fetchUserInfoByPostId } from "../../util/fetchUserInfoByPostId";
import useFetchUserInfomation from "../../hooks/useFetchUserInformation";
import { SearchResult } from "./searchResult";
// import { shortenTitle, truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";
import { truncateString } from "../../util/trancateString";

const TopWrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  background: white;
  border-bottom: 0.5px solid ${colors.gray3};
`;

const AboveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) =>
    p.isSearchMode ? `12px 0px 20px 20px` : `12px 20px 4px 20px;`};
  margin-bottom: 4px;
`;

const StyledForm = styled.form`
  flex-grow: 1;
  margin-right: 12px;
`;

const InputWrapper = styled.label`
  width: 100%;
  margin-left: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const StyledInput = styled.input`
  outline: none;
  margin-left: 8px;
  border: none;
  background-color: transparent;
  color: ${colors.black};
  font-size: 12px;
  width: 100%;
  outline: none;
  font-weight: 600;

  &::placeholder {
    color: ${colors.gray3};
  }
`;

const StyledArrowLeft = styled(ArrowLeft)`
  flex-shrink: 0;
`;

const SearchButton = styled.button`
  white-space: nowrap;
  border: none;
  display: flex;
  height: 40px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  background: transparent;

  :disabled {
    color: ${colors.gray3};
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  gap: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: auto;
  align-items: center;
  padding: 0px 0px 0px 20px;
`;

const ArrowBottomWrap = styled.div`
  padding-right: 20px;
  position: fixed;
  top: 40.5px;
  right: 0px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 50%);
`;

const StyledArrowBottom = styled(ArrowBottom)`
  width: 20px;
  height: 20px;
  z-index: 1;
`;

const Button = styled.button`
  font-family: -apple-system, BlinkMacSystemFont, “Helvetica Neue”, Arial,
    “Hiragino Kaku Gothic ProN”, “Hiragino Sans”, Meiryo, sans-serif;
  flex-shrink: 0;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: ${colors.white};
  color: ${(p) => (p.focused ? colors.main : colors.gray1)};
  border-bottom: ${(p) => p.focused && `2px solid ${colors.black}`};
`;

const ScrollWrapper = styled.div`
  padding-top: 81px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 32px;
`;

const NavigateWrapper = styled.div`
  border-radius: 4px;
  border: 0.5px solid rgba(240, 193, 101, 0.4);
  background: rgba(240, 193, 101, 0.1);
  display: flex;
  height: 20px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 0px 20px;
  gap: 8px;
`;

const StyledRightArrow = styled(ArrowRight)`
  margin-left: auto;
`;

const BannerImg = styled.img`
  marin-bottom: 32px;
  padding: 0 20px;
`;

const CategoryWrapper = styled.div`
  // padding-bottom: 32px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px 8px 20px;
`;

const CategoryList = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 20px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CategoryListItem = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  height: 80px;
  width: 80px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  border: 0.5px solid ${colors.gray2};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CategoryName = styled.div`
  margin-bottom: 1px;
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 4px;
  border-right: 0.5px solid ${colors.gray2};
  border-bottom: 0.5px solid ${colors.gray2};
  border-left: 0.5px solid ${colors.gray2};
`;

const PostListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

const PostsCategorizedByCategory = styled.div`
  margin-bottom: 32px;
`;

const PostList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const TextWrap = styled.div`
  display: flex;
  margin-bottom: 12px;
  gap: 4px;
  align-items: center;
`;

const PostItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 94px;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled(Font14)``;

const UserWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const UserIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 90px;
  border: 0.2px solid ${colors.gray2};
`;

const Border = styled.div`
  ${(p) => p.isLast && `display:none;`}
  height: 0.5px;
  background: ${colors.gray2};
  margin: 16px 0px 16px 0px;
`;

const PostMoreButton = styled.button`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  border-radius: 90px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
  width: 100%;
  height: 44px;
  justify-content: center;
`;

const FootterWrapper = styled.div`
  background: var(--40, rgba(239, 239, 239, 0.4));
  padding: 32px 20px 20px 20px;
`;

const FotterPost = styled.div`
  margin-bottom: 32px;
`;

const FotterOther = styled.div`
  margin-bottom: 32px;
`;

const FotterTitle = styled.div`
  padding: 0px 8px 8px 0px;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const FotterItem = styled.div`
  display: flex;
  height: 44px;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const ModalInner = styled.div`
  position: fixed;
  max-height: 40%;
  bottom: 0px;
  padding: 12px 0px;
  background: ${colors.white};
  width: 100%;
  overflow-y: auto;
  border-radius: 12px 12px 0px 0px;
`;

const ModalBorder = styled.div`
  width: 36px;
  height: 4px;
  border-radius: 2.5px;
  background: ${colors.gray};
  margin: 0 auto;
`;

const BorderWrapper = styled.div``;

const Item = styled.div`
  margin: 0px 20px;
  display: flex;
  background: ${colors.white};
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const SearchModeModalInner = styled.div`
  position: absolute;
  top: 53px;
  padding: 0px 20px;
  background: ${colors.white};
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 40px);
`;

const SearchModeItem = styled.div`
  display: flex;
  background: ${colors.white};
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const DeleteButton = styled.button`
  border: none;
  background: transparent;
`;

const CorpoInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ModalInnerInner = styled.div`
  display: flex;
  padding: 40px 32px 32px 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  width: 676px;
  height: 582px;
  margin: auto;
`;

const Comment = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  background: #efefef;
  border-radius: 8px;
  margin-bottom: 32px;
`;

export const Home = () => {
  const pageNameState = useLocation();
  console.log(pageNameState.state);
  const [length, setLength] = useState(3);
  const [data, setData] = useState([]);
  const [categorizedData, setCategorisedData] = useState([]);
  const [showModalName, setShowModalName] = useState("");
  const [searchInputText, setSearchInputText] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [pageName, setPageName] = useState(
    pageNameState?.state?.pageNameState
      ? pageNameState?.state?.pageNameState
      : "ホーム"
  );
  const [showFotterName, setShowFotterName] = useState("");
  const navigate = useNavigate();
  const user = useFetchUser();
  const categoriesWithoutHome = useFetchCategory();
  const categories = [
    ...categoriesWithoutHome.slice(0, 0),
    { name: "ホーム" },
    { name: "新着", img: "🆕" },
    ...categoriesWithoutHome.slice(0),
  ];
  const { userData, refreshUserData } = useFetchUserInfomation();
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // 例: 画面幅が 600px 以下の場合に要素を表示する
      setShowElement(window.innerWidth >= 800);
    };

    // 初回の設定とリサイズ時にイベントを登録
    handleResize();
    window.addEventListener("resize", handleResize);

    // コンポーネントがアンマウントされたときにイベントを解除
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 空の依存リストで、初回のみ実行
  // [
  //   [{},{},{}],
  //   [{},{},{}],
  //   [{},{},{}],
  // ]

  const sortMap = (map) => {
    const sortedCategorizedData = [...map.entries()]
      .filter(([key]) => categories.some((category) => category.name === key))
      .sort(([keyA, valueA], [keyB, valueB]) => {
        const categoryA = categories.find((category) => category.name === keyA);
        const categoryB = categories.find((category) => category.name === keyB);
        return categoryA.number - categoryB.number;
      });
    console.log("ソート！！！", sortedCategorizedData, categories);
    // ソートされたデータを新しいMapオブジェクトに再構築
    const sortedCategorizedMap = new Map(sortedCategorizedData);
    return sortedCategorizedMap;
  };

  const createMap = (postsData) => {
    const map = new Map();
    map.set("新着", postsData);
    postsData.forEach((post) => {
      console.log(post);
      const key = post?.category[0];
      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key).push(post);
    });
    return map;
  };

  const fetchData = async () => {
    const result = await getDocs(collection(db, "Post"));
    const orderedByDateResult = result.docs.sort((a, b) => {
      console.log(a.data);
      if (a.data().postDate?.seconds !== b.data().postDate?.seconds) {
        return b.data().postDate?.seconds - a.data().postDate?.seconds;
      }
      return b.data().postDate?.nanoseconds - a.data().postDate?.nanoseconds;
    });
    const postsData = await Promise.all(
      orderedByDateResult.map(async (v) => {
        const userInfo = await fetchUserInfoByPostId(v.data().user_id);
        return {
          id: v.id,
          userInfo: userInfo,
          ...v.data(),
        };
      })
      // .sort((a, b) => b.postDate?.sconds - a.postDate?.seconds)
    );
    const map = createMap(postsData);
    setData(postsData);
    setCategorisedData(map);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 'smooth'を指定するとスムーズなアニメーションが発生します
    });
  }, [isSearchMode, pageName]);

  useEffect(() => {
    console.log("カテゴリーを設定するよ", pageNameState?.state?.pageNameState);
    setPageName(pageNameState?.state?.pageNameState ?? "ホーム");
  }, [pageNameState]);

  useEffect(() => {
    // コンポーネントの初回レンダー時の処理

    return () => {
      console.log("アンマウントされたよ。");
      setPageName("ホーム");
      // コンポーネントがアンマウントされるときのcleanup処理
    };
  }, []);

  const onSubmitSearchInput = async (e, text) => {
    console.log("おしたよね？？");
    e.preventDefault();

    //title,categoryから一文字でも当てはまった投稿データを取得。
    const postFilterdByTitle = data.filter((v) => {
      return v.title.includes(searchInputText || text);
    });
    const postFilterdByCategory = data.filter((v) => {
      return v.category?.includes(searchInputText || text);
    });
    console.log(searchInputText);
    if (searchInputText) {
      if (userData.searchedText?.length === 0 || !userData.searchedText) {
        setDoc(
          doc(db, "User", user.uid),
          {
            searchedText: [searchInputText],
          },
          { merge: true }
        );
      }
      setDoc(
        doc(db, "User", user.uid),
        {
          searchedText: [...userData?.searchedText, searchInputText],
        },
        { merge: true }
      );
    }
    navigate(`search/searchText=/${searchInputText || text}`, {
      state: { postsData: [...postFilterdByCategory, ...postFilterdByTitle] },
    });
    refreshUserData();
  };

  const deleteSearchedText = async (text, e) => {
    e.stopPropagation();
    setDoc(
      doc(db, "User", user.uid),
      {
        searchedText: userData.searchedText.filter((v) => v !== text),
      },
      { merge: true }
    );
    refreshUserData();
  };

  const getMoreItems = (categoryName) => {
    setPageName(categoryName);
  };

  const postsFilteredByCategory = data.filter((v) => {
    return v.category?.includes(pageName);
  });

  const onFocus = () => {
    if (!user) {
      navigate("login");
    }
    setIsSearchMode(true);
  };

  console.log(data, categorizedData);
  return (
    <>
      {!data || !categorizedData || !categories ? (
        <></>
      ) : (
        <TopWrapper>
          <Header>
            <AboveWrapper isSearchMode={isSearchMode}>
              {isSearchMode ? (
                <>
                  <StyledArrowLeft
                    color={colors.black}
                    onClick={() => setIsSearchMode(false)}
                  />
                  {/* <StyledForm onSubmit={(e) => onSubmitSearchInput(e)}> */}
                  <InputWrapper>
                    <Search color={colors.black} />
                    <StyledInput
                      type="text"
                      placeholder="キーワードから探しませんか？"
                      value={searchInputText}
                      onChange={(e) => setSearchInputText(e.target.value)}
                      //  onFocus={() => onFocus()}
                      //  onBlur={() => setIsSearchMode(false)}
                    />
                  </InputWrapper>
                  <SearchButton
                    disabled={!searchInputText}
                    onClick={(e) => onSubmitSearchInput(e, searchInputText)}
                  >
                    <Font12
                      bold
                      color={searchInputText ? colors.black : colors.gray3}
                    >
                      検索
                    </Font12>
                  </SearchButton>
                  {/* </StyledForm> */}
                </>
              ) : (
                <>
                  <Logo />
                  <LogoWrapper>
                    <SearchBig onClick={() => onFocus()} />
                    <Menu
                      color={colors.black}
                      onClick={() => setShowModalName("sideMenu")}
                    />
                  </LogoWrapper>
                </>
              )}
            </AboveWrapper>
            <BottomWrapper>
              {categories.map((v) => {
                return (
                  <Button
                    onClick={() => {
                      if (v.name === "ホーム") {
                        setPageName("");
                      }
                      setPageName(`${v.name}`);
                    }}
                    focused={pageName === v.name}
                  >
                    <Font12 bold color={pageName === v.name && colors.black}>
                      {v.name}
                    </Font12>
                  </Button>
                );
              })}
              {!isSearchMode && (
                <ArrowBottomWrap>
                  <StyledArrowBottom
                    onClick={() => setShowModalName("category")}
                    color={colors.gray3}
                  />
                </ArrowBottomWrap>
              )}
            </BottomWrapper>
          </Header>
          <ScrollWrapper>
            {pageName === "ホーム" ? (
              <MainWrapper>
                <NavigateWrapper
                  onClick={() =>
                    window.open(
                      "https://puzzled-hemisphere-6c6.notion.site/516f51b0f5ad487294eb624310ec8afe",
                      "_blank"
                    )
                  }
                >
                  <Eye />
                  <Font12 bold color={colors.main}>
                    リカルメについてわかるページはこちら
                  </Font12>
                  <StyledRightArrow color={colors.main} />
                </NavigateWrapper>
                {/* <BannerImg
                  src={Banner}
                  onClick={() =>
                    window.open(
                      "https://puzzled-hemisphere-6c6.notion.site/516f51b0f5ad487294eb624310ec8afe",
                      "_blank"
                    )
                  }
                /> */}
                <CategoryWrapper>
                  {/* <Title>
                    <Font18 bold color={colors.black}>
                      🗂️
                    </Font18>
                  </Title> */}
                  <CategoryList>
                    {categories
                      .filter((v) => v.name !== "ホーム")
                      .map((v) => {
                        return (
                          <CategoryListItem onClick={() => setPageName(v.name)}>
                            <IconWrapper>{v.img}</IconWrapper>
                            <CategoryName>
                              <Font10 bold color={colors.black}>
                                {v.name}
                              </Font10>
                            </CategoryName>
                          </CategoryListItem>
                        );
                      })}
                  </CategoryList>
                </CategoryWrapper>
                <PostListWrapper id="parentid">
                  {Array.from(sortMap(categorizedData).entries()).map(
                    (category, i) => {
                      return (
                        <PostsCategorizedByCategory>
                          <TextWrap>
                            {/* {
                              categories.find((v) => v.name === category[0])
                                ?.img
                            } */}
                            <Font20 bold color={colors.black}>
                              {category[0]}
                            </Font20>
                          </TextWrap>
                          <PostList isOdd={i % 2 !== 0}>
                            {category[1].slice(0, length).map((v, k) => {
                              console.log(k, category[1]);
                              return (
                                <div key={k}>
                                  <PostItem
                                    onClick={() => navigate(`post/${v.id}`)}
                                  >
                                    <ImgWrapper src={v.img ?? ""} />
                                    <ContentWrapper>
                                      <TagWrapper>
                                        {v.category && (
                                          <Tag>
                                            <Font10>
                                              {v.category.length === 2
                                                ? v.category.join(" ＞ ")
                                                : v.category}
                                            </Font10>
                                          </Tag>
                                        )}
                                      </TagWrapper>
                                      <Font14
                                        id="titleSpan"
                                        color={colors.black}
                                        bold
                                      >
                                        {truncateString(v.title)}
                                      </Font14>
                                      <PostPrice color={colors.black} bold>
                                        ¥{insertCommas(v.price)}
                                      </PostPrice>
                                      {/* vのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                                      <UserWrapper>
                                        <UserIcon
                                          src={v.userInfo?.userPhotoUrl}
                                        />
                                        <Font10 color={colors.gray1}>
                                          {v.userInfo?.userName}
                                        </Font10>
                                        <Font10 color={colors.gray1}>|</Font10>
                                        {/* <Font10> */}
                                        {calculateElapsedTime(v.postDate)}
                                        {/* </Font10> */}
                                      </UserWrapper>
                                    </ContentWrapper>
                                  </PostItem>
                                  <Border
                                    isLast={
                                      category[1].length < 3
                                        ? category[1].length - 1 === k
                                        : k === 2
                                    }
                                  />
                                </div>
                              );
                            })}
                          </PostList>
                          {length < category[1].length && (
                            <PostMoreButton
                              onClick={() => getMoreItems(category[0])}
                            >
                              <Font12 bold color={colors.main}>
                                {category[0]}の投稿をもっと見る
                              </Font12>
                            </PostMoreButton>
                          )}
                        </PostsCategorizedByCategory>
                      );
                    }
                  )}
                </PostListWrapper>
              </MainWrapper>
            ) : (
              <CategoryPgae
                postsData={pageName === "新着" ? data : postsFilteredByCategory}
                pageName={pageName}
              />
            )}
            <FootterWrapper>
              <FotterPost>
                <FotterTitle>
                  <Font10 bold color={colors.gray1}>
                    投稿
                  </Font10>
                </FotterTitle>
                <FotterItem>
                  <Font12 bold color={colors.black}>
                    検索
                  </Font12>
                  {showFotterName === "category" ? (
                    <ArrowTop
                      color={colors.gray3}
                      onClick={() => setShowFotterName("")}
                    />
                  ) : (
                    <ArrowBottom
                      color={colors.gray3}
                      onClick={() => setShowFotterName("category")}
                    />
                  )}
                </FotterItem>
                {showFotterName === "category" &&
                  categories
                    // .filter((v) => v.name !== "ホーム")
                    .map((v) => {
                      return (
                        <FotterItem onClick={() => setPageName(v.name)}>
                          <Font12 bold color={colors.gray1}>
                            {v.name}
                          </Font12>
                        </FotterItem>
                      );
                    })}
                <FotterItem
                  onClick={() => {
                    if (user) {
                      navigate(`register`);
                    } else {
                      navigate(`../login`);
                    }
                  }}
                >
                  <Font12 bold color={colors.black}>
                    投稿
                  </Font12>
                </FotterItem>
                <FotterItem
                  onClick={() => {
                    if (user) {
                      navigate(`/request`);
                    } else {
                      navigate(`../login`);
                    }
                  }}
                >
                  <Font12 bold color={colors.black}>
                    問い合わせ
                  </Font12>
                </FotterItem>
                <FotterItem
                  onClick={() => {
                    if (user) {
                      navigate(`mypage`);
                    } else {
                      navigate(`login`);
                    }
                  }}
                >
                  <Font12 bold color={colors.black}>
                    マイページ
                  </Font12>
                </FotterItem>
              </FotterPost>
              <FotterOther>
                <FotterTitle>
                  <Font10 bold color={colors.gray1}>
                    その他
                  </Font10>
                </FotterTitle>
                <FotterItem
                  onClick={() =>
                    window.open(
                      "https://puzzled-hemisphere-6c6.notion.site/516f51b0f5ad487294eb624310ec8afe",
                      "_blank"
                    )
                  }
                >
                  <Font12 bold color={colors.black}>
                    リカルメについて
                  </Font12>
                </FotterItem>
                <FotterItem>
                  <Font12 bold color={colors.black}>
                    その他
                  </Font12>
                  {showFotterName === "other" ? (
                    <ArrowTop
                      color={colors.gray3}
                      onClick={() => setShowFotterName("")}
                    />
                  ) : (
                    <ArrowBottom
                      color={colors.gray3}
                      onClick={() => setShowFotterName("other")}
                    />
                  )}
                </FotterItem>
                {showFotterName === "other" && (
                  <>
                    <FotterItem
                      onClick={() =>
                        window.open(
                          "https://docs.google.com/forms/d/e/1FAIpQLSclyZnfywVOVdsHA7TDlIqoezfULACkEqoSyk6ArQld72CJuA/viewform",
                          "_blank"
                        )
                      }
                    >
                      <Font12 bold color={colors.gray1}>
                        運営チームに問い合わせ
                      </Font12>
                    </FotterItem>
                    <FotterItem
                      onClick={() => navigate("login/terms-of-service")}
                    >
                      <Font12 bold color={colors.gray1}>
                        利用規約
                      </Font12>
                    </FotterItem>
                    <FotterItem
                      onClick={() => navigate("login/privacy-policy")}
                    >
                      <Font12 bold color={colors.gray1}>
                        プライバシーポリシー
                      </Font12>
                    </FotterItem>
                  </>
                )}
              </FotterOther>
              <CorpoInfo>
                <Corpo />
                <Font10 bold color={colors.gray1}>
                  2023
                </Font10>
                <Font10 bold color={colors.gray1}>
                  ricarme
                </Font10>
              </CorpoInfo>
            </FootterWrapper>
          </ScrollWrapper>
          {showModalName === "sideMenu" && (
            <Modal onClick={() => setShowModalName("")}>
              <SideMenu
                onClick={(e) => e.stopPropagation()}
                closeSideMenu={() => setShowModalName("")}
              />
            </Modal>
          )}
          {showModalName === "category" && (
            <Modal onClick={() => setShowModalName("")}>
              <ModalInner onClick={(e) => e.stopPropagation()}>
                <BorderWrapper>
                  <ModalBorder />
                </BorderWrapper>

                {categories.map((v) => {
                  return (
                    <Item
                      onClick={() => {
                        setPageName(v.name);
                        setShowModalName("");
                      }}
                    >
                      <Font12
                        bold
                        color={pageName === v.name ? colors.main : colors.black}
                      >
                        {v.name}
                      </Font12>
                      {pageName === v.name && <CheckMark color={colors.main} />}
                    </Item>
                  );
                })}
              </ModalInner>
            </Modal>
          )}
          {isSearchMode && (
            <SearchModeModalInner>
              {userData.searchedText?.length === 0 || !userData.searchedText ? (
                <SearchModeItem>
                  <Font12 color={colors.black}>検索履歴はありません</Font12>
                </SearchModeItem>
              ) : (
                userData.searchedText
                  .slice()
                  .reverse()
                  .map((text) => {
                    return (
                      <SearchModeItem
                        onClick={(e) => {
                          console.log("おしたよね？？");
                          onSubmitSearchInput(e, text);
                          console.log("おしたよね？？");
                        }}
                      >
                        <Font12 bold color={colors.black}>
                          {text}
                        </Font12>
                        <DeleteButton
                          onClick={(e) => deleteSearchedText(text, e)}
                        >
                          <Font12 color={colors.error}>削除</Font12>
                        </DeleteButton>
                      </SearchModeItem>
                    );
                  })
              )}
            </SearchModeModalInner>
          )}
          {showElement && (
            <Modal onClick={() => {}}>
              <ModalInnerInner onClick={(e) => e.stopPropagation()}>
                <div
                  style={{
                    fontSize: 36,
                    fontWeight: 600,
                    textAlign: "center",
                    marginBottom: 32,
                  }}
                >
                  現在リカルメはモバイルサイズでのみ
                  <br />
                  サービス提供しています
                </div>

                <img src={QR3} />
                <div style={{}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // marginBottom: 32,
                    }}
                  >
                    <img
                      src={Sankaku}
                      style={{
                        width: 16,
                        height: 16,
                        marginTop: 8,
                        marginBottom: -1,
                      }}
                    />
                  </div>
                  <Comment>
                    スマホでQRコードをスキャンすることでリカルメのサービスページに飛べます
                  </Comment>
                </div>

                <QrLogo />
              </ModalInnerInner>
            </Modal>
          )}
        </TopWrapper>
      )}
    </>
  );
};
