import { colors } from "../common-componnets/color";
import { Font10 } from "../common-componnets/typography";
import styled from "styled-components";

//ラストログイン
export const caluculateLastLoginHourTime = (lastLoginTime) => {
  const targetDate = new Date(lastLoginTime);
  const currentDate = new Date();
  // 時間差を計算
  const timeDifference = currentDate - targetDate;
  // ミリ秒単位から時間単位に変換
  console.log(lastLoginTime, targetDate, timeDifference);
  return Math.floor(timeDifference / (1000 * 60 * 60));
};

//投稿日時
export const postedTime = (date) => {
  // データ
  const nanoseconds = date?.nanoseconds;
  const seconds = date?.seconds;

  // 秒とナノ秒をミリ秒に変換して加算
  const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1e6);

  // ミリ秒を使用してDateオブジェクトを生成
  const transformedDate = new Date(milliseconds);
  const year = transformedDate.getFullYear();
  const month = transformedDate.getMonth() + 1;
  const day = transformedDate.getDate();

  return `${year}年${month}月${day}日`;
};

//ラストログイン、投稿日時
export const transformedDate = (dateProps) => {
  const date = new Date(dateProps);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}年${month}月${day}日`;
};

//chat時間
export const transformedTime = (dateProps) => {
  console.log(dateProps);
  const date = new Date(dateProps);
  const hours = String(date?.getHours()).padStart(2, "0");
  const minutes = String(date?.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

const LikedText = styled.div`
  display: flex;
  gap: 1px;
`;

export const formatElapsedTime = (elapsedTime) => {
  if (elapsedTime < 3600) {
    return (
      <LikedText>
        <Font10 color={colors.gray1}>{Math.floor(elapsedTime / 60)}分前</Font10>
      </LikedText>
    );
  } else if (elapsedTime < 24 * 3600) {
    return (
      <LikedText>
        <Font10 color={colors.gray1}>
          {Math.floor(elapsedTime / 3600)}時間前
        </Font10>
      </LikedText>
    );
  } else if (elapsedTime < 365 * 24 * 3600) {
    return (
      <LikedText>
        <Font10 color={colors.gray1}>
          {Math.floor(elapsedTime / (24 * 3600))}日前
        </Font10>
      </LikedText>
    );
  } else {
    return (
      <LikedText>
        <Font10 color={colors.gray1}>
          {Math.floor(elapsedTime / (365 * 24 * 3600))}年前
        </Font10>
      </LikedText>
    );
  }
};

export const calculateElapsedTime = (objectTime) => {
  // オブジェクトの時間をミリ秒に変換
  const objectTimeMilliseconds =
    objectTime?.seconds * 1000 + Math.floor(objectTime?.nanoseconds / 1e6);

  // 現在時刻を取得
  const now = new Date();

  // オブジェクトの時間をDateオブジェクトに変換
  const objectDate = new Date(objectTimeMilliseconds);

  // 経過した時間を計算
  const elapsedTimeInSeconds = (now - objectDate) / 1000;

  return formatElapsedTime(elapsedTimeInSeconds);
};

export const formatElapsedTimee = (elapsedTime) => {
  console.log("じかんだよ", elapsedTime);
  if (elapsedTime < 3600) {
    return `${Math.floor(elapsedTime / 60)}分前`;
  } else if (elapsedTime < 24 * 3600) {
    return `${Math.floor(elapsedTime / 3600)}時間前`;
  } else if (elapsedTime < 365 * 24 * 3600) {
    return `${Math.floor(elapsedTime / (24 * 3600))}日前`;
  } else {
    return `${Math.floor(elapsedTime / (365 * 24 * 3600))}年前`;
  }
};

export const calculateElapsedTimee = (objectTime) => {
  // オブジェクトの時間をミリ秒に変換
  const objectTimeMilliseconds =
    objectTime?.seconds * 1000 + Math.floor(objectTime?.nanoseconds / 1e6);

  // 現在時刻を取得
  const now = new Date();

  // オブジェクトの時間をDateオブジェクトに変換
  const objectDate = new Date(objectTimeMilliseconds);

  // 経過した時間を計算
  const elapsedTimeInSeconds = (now - objectDate) / 1000;

  return formatElapsedTimee(elapsedTimeInSeconds);
};
