import styled from "styled-components";
import { colors } from "../../common-componnets/color";
import useFetchUser from "../../hooks/useFetchUser";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase-config";
import { Font10, Font14, Font16 } from "../../common-componnets/typography";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  postedTime,
  transformedDate,
} from "../../util/date";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";

const NoresultWrapper = styled.div`
  padding-top: 319px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PostList = styled.div`
  padding: 104px 20px 20px 20px;
  display: flex;
  flex-direction: column;
`;

const PostItem = styled.div`
  display: flex;
  align-items: center;
  height: 94px;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled.div`
  margin-bottom: 12px;
`;

const Border = styled.div`
  ${(p) => p.isLast && `display:none;`}
  height: 0.5px;
  background: ${colors.gray2};
  margin: 16px 0px 16px 0px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const LikedText = styled.div`
  display: flex;
  gap: 1px;
`;

const RequestedOwnPosts = ({ setShowinfPageName }) => {
  const user = useFetchUser();
  const [postData, setPostData] = useState();
  const navigate = useNavigate();

  const fetchRequestedPosts = async () => {
    const postCollectionRef = collection(db, "Post");
    const q = query(postCollectionRef, where("user_id", "==", user.uid));
    const ownPostDocsRef = await getDocs(q);
    const arra1 = await Promise.all(
      ownPostDocsRef.docs.map(async (ownPost) => {
        const userCollectionRef = collection(db, "User");
        const userDocsRef = await getDocs(userCollectionRef);
        const array2 = await Promise.all(
          userDocsRef.docs.map(async (user) => {
            console.log(ownPost.id, user.id);
            const ownPostMessagesDocsRef = collection(
              db,
              "Post",
              ownPost.id,
              "ChatRoom",
              user.id,
              "Messages"
            );
            const ownPostMessagesDocsSnapShot = await getDocs(
              ownPostMessagesDocsRef
            );
            console.log(
              ownPostMessagesDocsSnapShot.docs.map((v) => v.data()).length
            );
            if (
              ownPostMessagesDocsSnapShot.docs.map((v) => v.data()).length !== 0
            ) {
              return { id: ownPost.id, ...ownPost.data() };
            } else {
              return false;
            }
          })
        );
        console.log(array2);
        return array2.filter((v) => v !== false);
      })
    );
    const emptyElementRemovedArray = arra1.reduce(
      (accumulator, currentArray) => {
        // 現在の配列が空でない場合、その要素を紐づけた新しい配列を作成
        if (currentArray.length > 0) {
          return accumulator.concat(currentArray);
        }

        return accumulator;
      },
      []
    );
    // グルーピングを行うためのオブジェクトを作成
    const groupedById = emptyElementRemovedArray.reduce((acc, obj) => {
      const key = obj.id;
      acc[key] = acc[key] || [];
      acc[key].push(obj);
      return acc;
    }, {});

    // グルーピングされたオブジェクトから新しい配列を生成
    const newArray = Object.values(groupedById).map((group) => {
      return group.slice(0, 1);
    });
    console.log(arra1, emptyElementRemovedArray, groupedById, newArray);
    setPostData(newArray.flat());
  };
  useEffect(() => {
    if (user) {
      fetchRequestedPosts();
    }
  }, [user]);

  console.log(postData);
  return (
    <>
      {!postData ? (
        <></>
      ) : postData?.length === 0 ? (
        <NoresultWrapper>
          <Font16 color={colors.gray3} bold>
            まだ問い合わせはありません
          </Font16>
        </NoresultWrapper>
      ) : (
        <PostList>
          {postData?.map((v, i) => {
            return (
              <div key={i}>
                <PostItem
                  onClick={() => {
                    navigate(v.id);
                  }}
                >
                  <ImgWrapper src={v.img ?? ""} />
                  <ContentWrapper>
                    <TagWrapper>
                      {v.category && (
                        <Tag>
                          <Font10>
                            {v.category.length === 2
                              ? v.category.join(" ＞ ")
                              : v.category}
                          </Font10>
                        </Tag>
                      )}
                    </TagWrapper>
                    <Font14 bold color={colors.black}>
                      {truncateString(v.title)}
                    </Font14>
                    <PostPrice>
                      <Font14 bold color={colors.black}>
                        ¥{insertCommas(v.price)}
                      </Font14>
                    </PostPrice>
                    <UserWrapper>
                      <LikedText>
                        <Font10 color={colors.gray1}>
                          {v.likedNumber ?? 0}
                        </Font10>
                        <Font10 color={colors.gray1}>いいね</Font10>
                      </LikedText>
                      <Font10>|</Font10>
                      <Font10>{calculateElapsedTime(v.postDate)}</Font10>
                    </UserWrapper>
                  </ContentWrapper>
                </PostItem>
                <Border isLast={postData.length - 1 === i} />
              </div>
            );
          })}
        </PostList>
      )}
    </>
  );
};

export default RequestedOwnPosts;
