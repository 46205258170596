import { useNavigate } from "react-router-dom";
import { Button } from "../../common-componnets/button";
import styled from "styled-components";
import { Font10, Font12, Font14 } from "../../common-componnets/typography";
import useFetchUserInfomation from "../../hooks/useFetchUserInformation";
import useFetchUser from "../../hooks/useFetchUser";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { Tab } from "../../common-componnets/tab";
import { useState, useRef, useEffect } from "react";
import { colors } from "../../common-componnets/color";
import MyPost from "./mypost";
import LikedPost from "./likedPost";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Modal } from "../../common-componnets/modal";
import { SideMenu } from "../home/sideMenu";

const AccountWrapper = styled.div``;

const TopWrapper = styled.div`
  position: fixed;
  top: 0px;
  background: white;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 20px;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const Profile = styled.div`
  padding: 16px 20px 16px 20px;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const ProfileInner = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const ProfileImg = styled.img`
  width: 44px;
  object-fit: cover;
  height: 44px;
  border-radius: 90px;
`;

const InnerRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const Discription = styled.div`
  position: relative;
  margin-top: 8px;
  display: flex;
  padding: 8px 12px;
  display: inline-flex;
  border-radius: 8px;
  background: ${colors.gray};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -15px;
    margin-left: 14px;
    border: 9px solid transparent;
    border-bottom: 15px solid ${colors.gray};
  }
`;

const ButtonWrapper = styled.div`
  margin: 16px 0px 0px 0px;
`;

const MyPage = () => {
  const user = useFetchUser();
  const navigate = useNavigate();
  const { userData } = useFetchUserInfomation();
  const [showingPageName, setShowinfPageName] = useState("myPost");
  const [showModalName, setShowModalName] = useState("");
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log(user.uid);
    } else {
      navigate("../login");
    }
  });
  const elementRef = useRef(null);
  var height = 0;
  useEffect(() => {
    console.log("taksa", elementRef.current);
    // コンポーネントがマウントされた後に実行されるコード
    if (elementRef.current) {
      height = elementRef.current.clientHeight;
      console.log("要素の高さ:", height, "ピクセル");
    }
  }, []); // 空の配列は初回のみ実行することを示します

  console.log(userData);
  return (
    <>
      {user && userData && (
        <AccountWrapper ref={elementRef}>
          <TopWrapper>
            <Header>
              <ArrowLeft color={colors.black} onClick={() => navigate(-1)} />
              <Font14 color={colors.black} bold>
                マイページ
              </Font14>
              <Menu
                color={colors.black}
                onClick={() => setShowModalName("topMenu")}
              />
            </Header>
            <Profile>
              <ProfileInner>
                <ProfileImg src={userData.userPhotoUrl} />
                <InnerRight>
                  <Font14 color={colors.black} bold>
                    {userData.userName}
                  </Font14>
                  <Font10>{userData.mailaddress}</Font10>
                </InnerRight>
              </ProfileInner>
              <Discription>
                <Font10 color={colors.black}>{userData.discription}</Font10>
              </Discription>
              <ButtonWrapper>
                <Button onClick={() => navigate(`accountInfoEdit/${user.uid}`)}>
                  <Font12 bold color={colors.white}>
                    会員情報を編集する
                  </Font12>
                </Button>
              </ButtonWrapper>
            </Profile>
            <Tab
              leftText={"あなたの投稿"}
              rightText={"いいねした投稿"}
              onClickLeft={() => setShowinfPageName("myPost")}
              onClickRight={() => setShowinfPageName("likedPost")}
              isFocusedLeft={showingPageName === "myPost"}
              isFocusedRight={showingPageName === "likedPost"}
            />
          </TopWrapper>
        </AccountWrapper>
      )}
      {showModalName === "topMenu" && (
        <Modal onClick={() => setShowModalName("")}>
          <SideMenu
            onClick={(e) => e.stopPropagation()}
            closeSideMenu={() => setShowModalName("")}
          />
        </Modal>
      )}
      {showingPageName === "myPost" && <MyPost />}
      {showingPageName === "likedPost" && <LikedPost />}
    </>
  );
};

export default MyPage;
