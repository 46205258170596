import styled from "styled-components";
import {
  Font10,
  Font12,
  Font14,
  Font16,
} from "../../common-componnets/typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  postedTime,
} from "../../util/date";
import { colors } from "../../common-componnets/color";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { fetchUserInfoByPostId } from "../../util/fetchUserInfoByPostId";
import { useEffect, useState } from "react";
import useFetchUserInfomation from "../../hooks/useFetchUserInformation";
import useFetchUser from "../../hooks/useFetchUser";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0px 12px 20px;
  position: fixed;
  top: 0;
  width: calc(100% - 20px);
  background: ${colors.white};
`;

const StyledForm = styled.form`
  flex-grow: 1;
  margin-left: 8px;
  ${(p) => !p.isSearchMode && `padding-right: 20px;`}
`;

const InputWrapper = styled.label`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  color: ${colors.black};
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin-left: 8px;

  :placeholder {
    color: ${colors.black};
  }
`;

const StyledArrowLeft = styled(ArrowLeft)`
  flex-shrink: 0;
`;

const SearchButton = styled.button`
  white-space: nowrap;
  border: none;
  display: flex;
  height: 40px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  background: transparent;

  :disabled {
    color: ${colors.gray3};
  }
`;

const NoResultWrapper = styled.div`
  padding: 129px 0px 63px 0px;
  text-align: center;
`;

const PostList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 20px;
`;

const PostItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 94px;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding 2px 0px
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid var(--, #efefef);
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled(Font14)`
  margin-bottom: 12px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 12px;
  white-space: nowrap;
`;

const UserIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 90px;
`;

const Border = styled.div`
  height: 0.5px;
  background: #ddd;
  margin: 16px 0px 16px 0px;
`;

const SearchModeModalInner = styled.div`
  position: absolute;
  top: 53px;
  padding: 0px 20px;
  background: ${colors.white};
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 40px);
  z-index: 100;
`;

const SearchModeItem = styled.div`
  display: flex;
  background: ${colors.white};
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const DeleteButton = styled.button`
  border: none;
  background: transparent;
`;

export const SearchResult = () => {
  const { searchText } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const posts = location.state.postsData;
  const [isSearchMode, setIsSearchMode] = useState(false);
  const { userData, refreshUserData } = useFetchUserInfomation();
  const user = useFetchUser();
  const [searchInputText, setSearchInputText] = useState("");

  const onSubmitSearchInput = async (e, text) => {
    e.preventDefault();
    console.log("hisotry", text, "input", searchInputText);
    const result = await getDocs(collection(db, "Post"));
    const data = await Promise.all(
      result.docs.map(async (v) => {
        const userInfo = await fetchUserInfoByPostId(v.data().user_id);
        return {
          id: v.id,
          userInfo: userInfo,
          ...v.data(),
        };
      })
    );
    const postFilterdByTitle = data.filter((v) => {
      return v.title.includes(searchInputText || text);
    });
    const postFilterdByCategory = data.filter((v) => {
      return v.category?.includes(searchInputText || text);
    });
    console.log(searchInputText, postFilterdByTitle, postFilterdByCategory);
    if (searchInputText) {
      if (userData.searchedText?.length === 0 || !userData?.searchedText) {
        setDoc(
          doc(db, "User", user.uid),
          {
            searchedText: [searchInputText || text],
          },
          { merge: true }
        );
      }
      setDoc(
        doc(db, "User", user.uid),
        {
          searchedText: [...userData.searchedText, searchInputText || text],
        },
        { merge: true }
      );
    }
    if (searchInputText || text) {
      navigate(
        `../search/searchText=/${searchInputText || text}`,

        {
          replace: true,
          state: {
            postsData: [...postFilterdByCategory, ...postFilterdByTitle],
          },
        }
      );
    } else {
      navigate(
        `../search/searchText=/ALL`,

        {
          replace: true,
          state: {
            postsData: data,
          },
        }
      );
    }
    refreshUserData();
    setIsSearchMode(false);
  };

  const deleteSearchedText = async (text, e) => {
    e.stopPropagation();
    setDoc(
      doc(db, "User", user.uid),
      {
        searchedText: userData.searchedText.filter((v) => v !== text),
      },
      { merge: true }
    );
    refreshUserData();
  };
  console.log(posts, location);
  return (
    <>
      <Header>
        <StyledArrowLeft onClick={() => navigate(-1)} color={colors.black} />
        <StyledForm isSearchMode={isSearchMode}>
          <InputWrapper>
            <Search color={colors.black} />
            <StyledInput
              type="text"
              placeholder={searchText}
              value={searchInputText}
              onChange={(e) => setSearchInputText(e.target.value)}
              onFocus={() => setIsSearchMode(true)}
              // onBlur={() =>  setIsSearchMode(false)}
            />
          </InputWrapper>
        </StyledForm>
        {isSearchMode && (
          <SearchButton
            disabled={!searchInputText}
            onClick={(e) => onSubmitSearchInput(e, searchInputText)}
          >
            <Font12 bold color={searchInputText ? colors.black : colors.gray3}>
              検索
            </Font12>
          </SearchButton>
          // <CancelButton onClick={() => setIsSearchMode(false)}>
          //   <Font12 color={colors.gray1}>キャンセル</Font12>
          // </CancelButton>
        )}
      </Header>
      {posts.length === 0 ? (
        <NoResultWrapper>
          <Font16 bold color={colors.gray3}>
            検索に関連する投稿はありません
          </Font16>
        </NoResultWrapper>
      ) : (
        <PostList>
          {posts.map((v, i) => {
            return (
              <div key={i}>
                <PostItem onClick={() => navigate(`../post/${v.id}`)}>
                  <ImgWrapper src={v.img ?? ""} />
                  <ContentWrapper>
                    <TagWrapper>
                      {v.category && (
                        <Tag>
                          <Font10>
                            {v.category.length === 2
                              ? v.category.join(" ＞ ")
                              : v.category}
                          </Font10>
                        </Tag>
                      )}
                    </TagWrapper>
                    <Font14 bold color={colors.black}>
                      {truncateString(v.title)}
                    </Font14>
                    <PostPrice bold color={colors.black}>
                      ￥{insertCommas(v.price)}
                    </PostPrice>
                    {/* vのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                    <UserWrapper>
                      <UserIcon src={v.userInfo.userPhotoUrl} />
                      <Font10>{v.userInfo.userName}</Font10>
                      <Font10 color={colors.gray1}>|</Font10>
                      <Font10>{calculateElapsedTime(v.postDate)}</Font10>
                    </UserWrapper>
                  </ContentWrapper>
                </PostItem>
                <Border />
              </div>
            );
          })}
          {/* <PostMoreButton onClick={() => getMoreItems()}>
          <Font12 color={colors.main}>新着の投稿をもっと見る</Font12>
        </PostMoreButton> */}
        </PostList>
      )}
      {isSearchMode && (
        <SearchModeModalInner>
          {userData.searchedText?.length === 0 || !userData.searchedText ? (
            <SearchModeItem>
              <Font12 color={colors.black}>検索履歴はありません</Font12>
            </SearchModeItem>
          ) : (
            userData.searchedText
              .slice()
              .reverse()
              .map((text) => {
                return (
                  <SearchModeItem
                    onClick={(e) => {
                      onSubmitSearchInput(e, text);
                    }}
                  >
                    <Font12 bold color={colors.black}>
                      {text}
                    </Font12>
                    <DeleteButton onClick={(e) => deleteSearchedText(text, e)}>
                      <Font12 color={colors.error}>削除</Font12>
                    </DeleteButton>
                  </SearchModeItem>
                );
              })
          )}
        </SearchModeModalInner>
      )}
    </>
  );
};
