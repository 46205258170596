import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase-config";
import { useEffect, useState } from "react";

// １、利用しているコンポーネントが再レンダー
// ２、useFetchUserが再発火
// ３、userがまずundefinedで返される。
// ４、useEffectが発火する。
//    onAuthStateChangedのコールバックが発火する。
// ５、setUserが発火し、useFetchUserが再レンダー
// ６、userがオブジェクトで返される。
// ７、useEffectが発火する。
//    onAuthStateChangedのコールバックが発火する。
//    setUserが発火し、useFetchUserが再レンダー
//    userがオブジェクトで返される。

const useFetchUser = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("setUser");
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array to ensure the effect runs only once

  console.log(user);
  return user;
};

export default useFetchUser;
