import { useEffect, useState } from "react";
import useFetchUser from "../../hooks/useFetchUser";
import { collection, db, doc, getDoc, getDocs } from "../../firebase-config";
import { useParams, useNavigate } from "react-router-dom";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  postedTime,
  transformedDate,
} from "../../util/date";
import styled from "styled-components";
import { Font10, Font14 } from "../../common-componnets/typography";
import { colors } from "../../common-componnets/color";
import { ReactComponent as ArrowLeft } from "../../assets/arrowLeft.svg";
import { Modal } from "../../common-componnets/modal";
import { SideMenu } from "../home/sideMenu";
import { Tab } from "../../common-componnets/tab";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import RequestedOwnPosts from "./requestedUsers";
import ContactedPosts from "./contactedPosts";
import { truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";

const RequestWrapper = styled.div`
  position: fixed;
  top: 0px;
  background: white;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 20px;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const RequestedUsersWrapper = styled.div`
  padding: 104px 20px 20px 20px;
  display: flex;
  flex-direction: column;
`;

const PostItem = styled.div`
  display: flex;
  align-items: center;
  height: 94px;
  gap: 12px;
  padding: 0px 0px 16px 0px;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled.div`
  margin-bottom: 12px;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  ${(p) => !p.isLast && `border-bottom: 0.5px solid ${colors.gray2}`};
`;

const ProfileImg = styled.img`
  margin-right: 12px;
  width: 44px;
  object-fit: cover;
  height: 44px;
  border-radius: 90px;
  flex-shrink: 0;
`;

const LinkWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const LikedText = styled.div`
  display: flex;
  gap: 1px;
`;

const RequestedUsers = () => {
  const navigate = useNavigate();
  const [showModalName, setShowModalName] = useState("");
  const [showingPageName, setShowinfPageName] = useState("requestedUsers");
  const user = useFetchUser();
  const { postId } = useParams();
  const [post, setPost] = useState([]);

  const [requestedUsers, setRequestedUsers] = useState([]);

  const fetchDate = async () => {
    const docRef = doc(db, "Post", postId);
    const docSnap = await getDoc(docRef);
    setPost(docSnap.data());
    const postCollectionRef = collection(db, "Post");
    const postDocsRef = await getDocs(postCollectionRef);
    const arra1 = await Promise.all(
      postDocsRef.docs.map(async (ownPost) => {
        const userCollectionRef = collection(db, "User");
        const userDocsRef = await getDocs(userCollectionRef);
        const array2 = await Promise.all(
          userDocsRef.docs.map(async (user) => {
            console.log(ownPost.id, user.id);
            const postMessagesDocsRef = collection(
              db,
              "Post",
              postId,
              "ChatRoom",
              user.id,
              "Messages"
            );
            const postMessagesDocsSnapShot = await getDocs(postMessagesDocsRef);
            if (
              postMessagesDocsSnapShot.docs.map((post) => post.data())
                .length !== 0
            ) {
              const lastMessage = postMessagesDocsSnapShot.docs
                .map((post) => post.data())
                .filter((post) => post.user_id !== user.uid)
                .sort((a, b) => b.timestamp - a.timestamp)[0];
              return {
                userId: user.id,
                userImg: user.data().userPhotoUrl,
                userName: user.data().userName,
                lastmessage: lastMessage.text,
                messageDate: lastMessage.timestamp,
              };
            } else {
              return false;
            }
          })
        );
        return array2.filter((post) => post !== false);
      })
    );
    setRequestedUsers(...arra1);
  };
  useEffect(() => {
    if (user) {
      fetchDate();
    }
  }, [user]);
  console.log(requestedUsers, post);
  // [
  //     {
  //         img,
  //         userName,
  //         lastmessage,
  //         messageDate
  //     }
  // ]
  return (
    <>
      <RequestWrapper>
        <Header>
          <ArrowLeft color={colors.gray3} onClick={() => navigate(-1)} />
          <Font14 bold>問い合わせ</Font14>
          <Menu
            color={colors.gray3}
            onClick={() => setShowModalName("sideMenu")}
          />
        </Header>
        <Tab
          leftText={"問い合わせのある投稿"}
          rightText={"問い合わせした投稿"}
          onClickLeft={() => setShowinfPageName("requestedUsers")}
          onClickRight={() => setShowinfPageName("contactedPosts")}
          isFocusedLeft={showingPageName === "requestedUsers"}
          isFocusedRight={showingPageName === "contactedPosts"}
        />
      </RequestWrapper>
      {showingPageName === "requestedUsers" && (
        <RequestedUsersWrapper>
          {!post || !requestedUsers ? (
            <></>
          ) : (
            <PostItem>
              <ImgWrapper src={post.img ?? ""} />
              <ContentWrapper>
                <TagWrapper>
                  {post.category && (
                    <Tag>
                      <Font10>
                        {post.category.length === 2
                          ? post.category.join(" ＞ ")
                          : post.category}
                      </Font10>
                    </Tag>
                  )}
                </TagWrapper>
                <Font14 bold color={colors.black}>
                  {truncateString(post?.title)}
                </Font14>
                <PostPrice>
                  <Font14 bold color={colors.black}>
                    ¥{insertCommas(post.price)}
                  </Font14>
                </PostPrice>
                {/* postのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                <UserWrapper>
                  <LikedText>
                    <Font10 color={colors.gray1}>
                      {post.likedNumber ?? 0}
                    </Font10>
                    <Font10 color={colors.gray1}>いいね</Font10>
                  </LikedText>
                  <Font10>|</Font10>
                  <Font10>{calculateElapsedTime(post?.postDate)}</Font10>
                </UserWrapper>
              </ContentWrapper>
            </PostItem>
          )}
          {requestedUsers.map((userData, i) => {
            return (
              <Profile
                onClick={() =>
                  navigate(`/post/${postId}/chat/${userData.userId}`)
                }
                isLast={requestedUsers.length - 1 === i}
              >
                <ProfileImg src={userData.userImg} />
                <LinkWrapperInner>
                  <Font14 color={colors.black}>{userData.lastmessage}</Font14>

                  <UserWrapper>
                    <Font10>{userData.userName} </Font10>
                    <Font10>|</Font10>
                    <Font10>{postedTime(userData.messageDate)}</Font10>
                  </UserWrapper>
                </LinkWrapperInner>
              </Profile>
            );
          })}
        </RequestedUsersWrapper>
      )}

      {showingPageName === "contactedPosts" && <ContactedPosts />}
      {showModalName === "sideMenu" && (
        <Modal onClick={() => setShowModalName("")}>
          <SideMenu
            onClick={(e) => e.stopPropagation()}
            closeSideMenu={() => setShowModalName("")}
          />
        </Modal>
      )}
    </>
  );
};

export default RequestedUsers;
