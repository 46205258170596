import styled from "styled-components";
import { colors } from "../../common-componnets/color";
import useFetchUser from "../../hooks/useFetchUser";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import { Font10, Font14, Font16 } from "../../common-componnets/typography";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  postedTime,
  transformedDate,
} from "../../util/date";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";

const NoresultWrapper = styled.div`
  padding-top: 319px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PostList = styled.div`
  padding: 104px 20px 20px 20px;
  display: flex;
  flex-direction: column;
`;

const PostItem = styled.div`
  display: flex;
  align-items: center;
  height: 94px;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled.div`
  margin-bottom: 12px;
`;

const Border = styled.div`
  ${(p) => p.isLast && `display:none;`}
  height: 0.5px;
  background: ${colors.gray2};
  margin: 16px 0px 16px 0px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const LikedText = styled.div`
  display: flex;
  gap: 1px;
`;

const ContactedPosts = () => {
  const user = useFetchUser();
  const [postData, setPostData] = useState();
  const navigate = useNavigate();

  const fetchRequestedPosts = async () => {
    const postCollectionRef = collection(db, "Post");
    const postDocsRef = await getDocs(postCollectionRef);
    const arra1 = await Promise.all(
      postDocsRef.docs.map(async (post) => {
        const messagesDocsRef = collection(
          db,
          "Post",
          post.id,
          "ChatRoom",
          user.uid,
          "Messages"
        );
        const postMessagesDocsSnapShot = await getDocs(messagesDocsRef);
        console.log(postMessagesDocsSnapShot.docs);
        if (postMessagesDocsSnapShot.docs.map((v) => v.data()).length !== 0) {
          return { id: post.id, ...post.data() };
        } else {
          return false;
        }
      })
    );
    setPostData(arra1.filter((v) => v !== false));
  };
  useEffect(() => {
    if (user) {
      fetchRequestedPosts();
    }
  }, [user]);

  console.log(postData);
  return (
    <>
      {!postData ? (
        <></>
      ) : !postData ? (
        <NoresultWrapper>
          <Font16 color={colors.gray3} bold>
            まだ問い合わせした投稿はありません
          </Font16>
        </NoresultWrapper>
      ) : (
        <PostList>
          {postData.map((v, i) => {
            return (
              <div key={i}>
                <PostItem
                  onClick={() => navigate(`/post/${v.id}/chat/${user.uid}`)}
                >
                  <ImgWrapper src={v.img ?? ""} />
                  <ContentWrapper>
                    <TagWrapper>
                      {v.category && (
                        <Tag>
                          <Font10>
                            {v.category.length === 2
                              ? v.category.join(" ＞ ")
                              : v.category}
                          </Font10>
                        </Tag>
                      )}
                    </TagWrapper>
                    <Font14 bold color={colors.black}>
                      {truncateString(v.title)}
                    </Font14>
                    <PostPrice>
                      <Font14 bold color={colors.black}>
                        ¥{insertCommas(v.price)}
                      </Font14>
                    </PostPrice>
                    <UserWrapper>
                      <LikedText>
                        <Font10 color={colors.gray1}>
                          {v.likedNumber ?? 0}
                        </Font10>
                        <Font10 color={colors.gray1}>いいね</Font10>
                      </LikedText>
                      <Font10>|</Font10>
                      <Font10>{calculateElapsedTime(v.postDate)}</Font10>
                    </UserWrapper>
                    {/* vのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                  </ContentWrapper>
                </PostItem>
                <Border isLast={postData.length - 1 === i} />
              </div>
            );
          })}
        </PostList>
      )}
    </>
  );
};

export default ContactedPosts;
