import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import useFetchUser from "../../../hooks/useFetchUser";
import { useEffect, useState } from "react";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  postedTime,
  transformedDate,
} from "../../../util/date";
import { ReactComponent as Elipsis } from "../../../assets/ellipsis.svg";
import styled from "styled-components";
import { db } from "../../../firebase-config";
import {
  Font10,
  Font12,
  Font13,
  Font14,
  Font16,
  Font17,
} from "../../../common-componnets/typography";
import { Modal } from "../../../common-componnets/modal";
import { colors } from "../../../common-componnets/color";
import { ModalButton } from "../../../common-componnets/button";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../../util/trancateString";
import { insertCommas } from "../../../util/insertCommas";

const NoresultWrapper = styled.div`
  padding-top: 465px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyPostWrapper = styled.div`
  padding: 280px 20px 20px 20px;
`;

const PostItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled(Font14)`
  margin-bottom: 12px;
`;

const BogttomWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledElipsis = styled(Elipsis)`
  width: 12px;
  height: 12px;
`;

const ModalBorder = styled.div`
  width: 36px;
  height: 4px;
  border-radius: 2.5px;
  background: ${colors.gray};
  margin: 0 auto;
`;

const ModalInner = styled.div`
  position: fixed;
  max-height: 40%;
  bottom: 0px;
  padding: 12px 0px;
  background: ${colors.white};
  width: 100%;
  overflow-y: auto;
  border-radius: 12px 12px 0px 0px;
`;

const Item = styled.div`
  margin: 0px 20px;
  display: flex;
  background: ${colors.white};
  padding: 16px 0px;
  align-items: center;
  border-bottom: 0.5px solid ${colors.gray2};
`;

const DeleteModalInner = styled.div`
  position: fixed;
  background: ${colors.white};
  border-radius: 14px;
  width: 270px;
  top: 40%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px 0px;
  align-items: center;
  border-bottom: 0.5px solid ${colors.gray2};
`;
const ButtonWrapper = styled.div`
  display: flex;
`;

const Border = styled.div`
  ${(p) => p.isLast && `display:none;`}
  height: 0.5px;
  background: ${colors.gray2};
  margin: 16px 0px 16px 0px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

const LikedText = styled.div`
  display: flex;
  gap: 1px;
`;

const MyPost = ({ height }) => {
  const navigate = useNavigate();
  const user = useFetchUser();
  const [data, setData] = useState();
  const [showModalName, setShowModalName] = useState("");
  const [postId, setPostId] = useState("");

  const fetchMyPostData = () => {
    const q = query(collection(db, "Post"), where("user_id", "==", user.uid));
    getDocs(q).then((r) => {
      setData(
        r.docs.map((v) => {
          return {
            id: v.id,
            ...v.data(),
          };
        })
      );
    });
  };

  const deletePost = () => {
    deleteDoc(doc(db, "Post", postId)).then((v) => setShowModalName(""));
  };

  useEffect(() => {
    if (user) {
      fetchMyPostData();
    }
  }, [user]);

  return (
    <>
      {!data ? (
        <></>
      ) : data.length === 0 ? (
        <NoresultWrapper>
          <Font16 color={colors.gray3} bold>
            あなたの投稿はまだありません
          </Font16>
        </NoresultWrapper>
      ) : (
        <MyPostWrapper>
          {data.map((v, i) => {
            return (
              <>
                <PostItem
                //  onClick={() => navigate(`/post/${v.id}`)}
                >
                  <ImgWrapper src={v.img ?? ""} />
                  <ContentWrapper>
                    <TagWrapper>
                      {v.category && (
                        <Tag>
                          <Font10>
                            {v.category.length === 2
                              ? v.category.join(" ＞ ")
                              : v.category}
                          </Font10>
                        </Tag>
                      )}
                    </TagWrapper>
                    <Font14 bold color={colors.black}>
                      {truncateString(v.title)}
                    </Font14>
                    <PostPrice bold color={colors.black}>
                      ¥{insertCommas(v.price)}
                    </PostPrice>
                    {/* vのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                    <BogttomWrapper>
                      <UserWrapper>
                        <LikedText>
                          <Font10 color={colors.gray1}>
                            {v.likedNumber ?? 0}
                          </Font10>
                          <Font10 color={colors.gray1}>いいね</Font10>
                        </LikedText>
                        <Font10>|</Font10>
                        <Font10>{calculateElapsedTime(v.postDate)}</Font10>
                      </UserWrapper>
                      <StyledElipsis
                        color={colors.gray1}
                        onClick={() => {
                          setShowModalName("menu");
                          setPostId(v.id);
                        }}
                      />
                    </BogttomWrapper>
                  </ContentWrapper>
                </PostItem>
                <Border isLast={data.length - 1 === i} />
              </>
            );
          })}
        </MyPostWrapper>
      )}
      {showModalName === "menu" && (
        <Modal onClick={() => setShowModalName("")}>
          <ModalInner onClick={(e) => e.stopPropagation()}>
            <ModalBorder />
            <Item onClick={() => navigate(`edit/${postId}`)}>
              <Font12 bold color={colors.black}>
                編集する
              </Font12>
            </Item>
            {/* <Item>
              <Font12 bold color={colors.black}>
                非表示にする
              </Font12>
            </Item> */}
            <Item onClick={() => setShowModalName("delete")}>
              <Font12 bold color={colors.error}>
                投稿を削除する
              </Font12>
            </Item>
          </ModalInner>
        </Modal>
      )}
      {showModalName === "delete" && (
        <Modal>
          <DeleteModalInner>
            <TextWrapper>
              <Font17 bold color={colors.systemBlack}>
                確認
              </Font17>
              <Font13 color={colors.systemBlack}>
                本当にこの投稿を削除しますか？
              </Font13>
            </TextWrapper>
            <ButtonWrapper>
              <ModalButton onClick={() => setShowModalName("")}>
                <Font14 color={colors.blue}>キャンセル</Font14>
              </ModalButton>
              <ModalButton onClick={() => deletePost()}>
                <Font14 color={colors.error}>削除</Font14>
              </ModalButton>
            </ButtonWrapper>
          </DeleteModalInner>
        </Modal>
      )}
    </>
  );
};

export default MyPost;
