import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  calculateElapsedTime,
  caluculateLastLoginHourTime,
  postedTime,
  transformedDate,
} from "../../util/date";
import styled from "styled-components";
import { db } from "../../firebase-config";
import { Font10, Font14, Font16 } from "../../common-componnets/typography";
import useFetchUserInfomation from "../../hooks/useFetchUserInformation";
import { colors } from "../../common-componnets/color";
import { useNavigate } from "react-router-dom";
import { fetchUserInfoByPostId } from "../../util/fetchUserInfoByPostId";
import { truncateString } from "../../util/trancateString";
import { insertCommas } from "../../util/insertCommas";

const NoresultWrapper = styled.div`
  padding-top: 465px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyPostWrapper = styled.div`
  padding: 280px 20px 20px 20px;
`;

const PostItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const ImgWrapper = styled.img`
  width: 94px;
  object-fit: cover;
  height: 94px;
  border-radius: 4px;
  border: 0.5px solid ${colors.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2px 0px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid ${colors.gray};
  background: var(--40, rgba(239, 239, 239, 0.4));
`;

const PostPrice = styled(Font14)`
  margin-bottom: 12px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  gap: 6px;
  white-space: nowrap;
`;

const UserIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 90px;
`;

const Border = styled.div`
  ${(p) => p.isLast && `display:none;`}
  height: 0.5px;
  background: ${colors.gray2};
  margin: 16px 0px 16px 0px;
`;

const LikedPost = () => {
  const { userData } = useFetchUserInfomation();
  const [data, setData] = useState();
  const navigate = useNavigate();

  const fetchMyPostData = async () => {
    const result = await getDocs(collection(db, "Post"));
    const postData = await Promise.all(
      result.docs.map(async (v) => {
        const userInfo = await fetchUserInfoByPostId(v.data().user_id);
        return {
          id: v.id,
          userInfo: userInfo,
          ...v.data(),
        };
      })
    );
    const newData = postData.filter((post) => {
      return userData?.likedPostIds.includes(post.id);
    });
    console.log(newData);
    setData(newData);
  };

  useEffect(() => {
    fetchMyPostData();
  }, [userData]);

  console.log(data, userData);
  return (
    <>
      {!data ? (
        <></>
      ) : data.length === 0 ? (
        <NoresultWrapper>
          <Font16 color={colors.gray3} bold>
            いいねした投稿はまだありません
          </Font16>
        </NoresultWrapper>
      ) : (
        <MyPostWrapper>
          {data.map((v, i) => {
            return (
              <>
                <PostItem onClick={() => navigate(`/post/${v.id}`)}>
                  <ImgWrapper src={v.img ?? ""} />
                  <ContentWrapper>
                    <TagWrapper>
                      {v.category && (
                        <Tag>
                          <Font10>
                            {v.category.length === 2
                              ? v.category.join(" ＞ ")
                              : v.category}
                          </Font10>
                        </Tag>
                      )}
                    </TagWrapper>
                    <Font14 bold color={colors.black}>
                      {truncateString(v.title)}
                    </Font14>
                    <PostPrice bold color={colors.black}>
                      ¥{insertCommas(v.price)}
                    </PostPrice>
                    {/* vのユーザーidと同じidのドキュメントをUserコレクションから取ってくる。 */}
                    <UserWrapper>
                      <UserIcon src={v.userInfo?.userPhotoUrl} />
                      <Font10> {v.userInfo?.userName}</Font10>
                      <Font10>|</Font10>
                      <Font10>{calculateElapsedTime(v.postDate)}</Font10>
                    </UserWrapper>
                  </ContentWrapper>
                </PostItem>
                <Border isLast={data.length - 1 === i} />
              </>
            );
          })}
        </MyPostWrapper>
      )}
    </>
  );
};

export default LikedPost;
