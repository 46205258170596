export function truncateString(str) {
  if (str?.length > 15) {
    return str.substring(0, 15) + "...";
  }
  return str;
}

// v.titleに格納された文字列を取得

// タイトルがはみ出るかどうかを判断する関数
const isTitleOverflow = (originalTitle) => {
  console.log(originalTitle);
  var titleSpan = document.getElementById("titleSpan");
  if (titleSpan) {
    console.log(titleSpan);
    titleSpan.innerText = originalTitle;

    // テキストがはみ出るかどうかを判定
    return (
      titleSpan.offsetWidth > document.getElementById("parentid").offsetWidth
    );
  }

  return false;
};

// はみ出る場合は末尾を短縮して「...」に変更
export const shortenTitle = (originalTitle) => {
  console.log(originalTitle);
  var titleSpan = document.getElementById("titleSpan");
  var shortenedTitle = originalTitle;

  while (isTitleOverflow(originalTitle)) {
    // 最後の単語を削除
    shortenedTitle = shortenedTitle.replace(/\s+\S*$/, "...");
    titleSpan.innerText = shortenedTitle;
  }

  // タイトルを表示
  titleSpan.innerText = shortenedTitle;
};
