import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, doc, getDoc } from "../firebase-config";
import useFetchUser from "./useFetchUser";

const useFetchUserInfomation = () => {
  const user = useFetchUser();

  const [userData, setUserData] = useState();

  const refreshUserData = () => {
    if (user) {
      const docRef = doc(db, "User", user.uid);
      getDoc(docRef).then((v) => {
        setUserData(v.data());
      });
    }
  };

  useEffect(() => {
    refreshUserData(); // Initial fetch

    // You can add more conditions to determine when to refresh the data
    // For example, when a certain prop changes or a button is clicked.
  }, [user]);

  console.log(userData);
  return { userData, refreshUserData };
};
export default useFetchUserInfomation;
